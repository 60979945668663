<template>
  <div 
    class='my-10'
    fill-height
    align="center"
    height="auto"
  >
    <span class="bg"></span>
    <v-img
      class="pb-0 pt-10"
      justify="center"
      max-width="300"
      contain
      src= '../assets/ls_player_logo.svg'
    ></v-img>
    <v-card 
      class="mx-auto my-5 rounded-lg"
      max-width="350"
      height="100%"
      elevation="4"
    >
      <v-sheet class="mx-7 pt-5" justify=left align=left>
        <v-card-title class="px-0 pb-5">WEISS GROUP</v-card-title>

        <v-form
          ref="form"
          v-model="isLoginFormValid"
        >
          {{ $t('loginPage.username') }}:
          <v-text-field
            v-model="credentials.username"
            :error-messages="loginFormErrors.username"
            @input="loginFormErrors.username=[]"
            required
            outlined
            :rules ="[rules.required]"
          ></v-text-field>

          {{ $t('loginPage.password') }}:
          <v-text-field
            v-model="credentials.password"
            :error-messages="loginFormErrors.password"
            @input="loginFormErrors.password=[]"
            required
            outlined
            :rules ="[rules.required]"
            :type="'password'"
          ></v-text-field>

          <v-row class="error-message red--text" v-if="loginFormErrors.non_field_errors.length != 0">
            <span
              class="mb-7 ml-4" 
              v-for="(error, index) in loginFormErrors.non_field_errors" :key="index"
              v-text="error + ' '"
            />
          </v-row>

          <v-btn
            color="primary--text"
            @click="login"
            large
            right
          > {{ $t('loginPage.loginButton') }} </v-btn>
        </v-form>
      </v-sheet>
      
      <v-card-actions class="justify-center">
        <locale-switcher />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue"
import { localizeRoute } from '@/i18n'

export default {
  name: 'LoginPage',
  components: {
    LocaleSwitcher,
  },
  data: () => ({
    valid: false,
    isLoginFormValid: false,
    loginFormErrors: {
      username: [],
      password: [],
      non_field_errors: [],
    },
    credentials: {
      username: '',
      password: '',
    },
    credentialsDefault: {
      username: '',
      password: '',
    }
  }),
  computed: {
    rules() {
      return {
        required: value => !!value || value === 0 || this.$t('event_form.rules.required')
      }
    }
  },
  methods: {
    /**
     * Авторизует пользователя и перенаправляет на домашнюю страницу.
     */
    login() {
      this.$refs.form.validate()
      if (!this.isLoginFormValid) {
        return
      }
      this.$store.dispatch('login', this.credentials)
      .then( () => {

        this.credentials = Object.assign({}, this.credentialsDefault)

        let route = localizeRoute({name: 'home'})
        this.$router.push(route)
      })
      .catch( errors => {
        console.log(errors)
        Object.assign(this.loginFormErrors, errors)
      })
    },
  }
}
</script>

<style scoped>
  .bg {
    background: url('../assets/lm_login_bg.svg') no-repeat center center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    background-position: top;
    background-color: #f0f0f0;
    /* transform: scale(1.1); */
  }
</style>